<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-button type="primary" @click="addCategory({ type:'add', pid:0 })" icon="el-icon-plus">新增</el-button>
    </div>
    <div>
      <el-table
        :data="tableData"
        stripe
        row-key="id"
        border
        lazy
        ref="table"
        :load="load"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        style="width: 100%">
        <el-table-column
          prop="name"
          label="类目名">
        </el-table-column>
        <el-table-column
          label="类目图标"
          width="120">
          <template slot-scope="scope">
            <div class="imgbox">
              <img :src="scope.row.icon_url" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="pic_url"
          label="类目图片"
          width="120">
          <template slot-scope="scope">
            <div class="imgbox">
              <img :src="scope.row.pic_url" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序">
        </el-table-column>
        <el-table-column
          prop="keywords"
          label="关键字">
        </el-table-column>
        <el-table-column
          prop="description"
          label="简介">
        </el-table-column>
        <el-table-column
          prop="level"
          label="级别">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="addCategory({ type:'add', pid: scope.row.id })"
              type="text"
              size="small">
              新增
            </el-button>
            <el-button
              @click.native.prevent="addCategory({ type:'update', pid: scope.row.pid }, scope.row)"
              type="text"
              size="small">
              编辑
            </el-button>
            <el-button
              @click.native.prevent="deleteRow(scope.row)"
              type="text"
              size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>

    <!-- <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div> -->

    <el-dialog :title="editType==='add'?'新增商品类目':'修改商品类目'" :visible.sync="dialogVisible" :close-on-click-modal="false">
      <add-category ref="category" :editType="editType" @cancelOperate="cancelOperate" :categoryPid="categoryPid" :categoryId="categoryId" :categoryInfo="categoryInfo"></add-category>
    </el-dialog>
    

  </div>
</template>

<script>
import { sureMessageBox } from '@/utils/common'
import { queryCategory, deleteCategory } from '@/api/itemCategory'
import addCategory from './addCategory'
export default {
  name:"itemcategory",
  components:{
    addCategory
  },
  props:{},
  data(){
    return {
      tableData: [],
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
      dialogVisible: false,
      editType: 'add', //编辑类型：add新增，update修改
      categoryId: null,
      categoryPid: null,
      categoryInfo: {},
      treeIds:[]
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getList();
  },
  computed:{},
  methods:{
    //初始商品类目
    getList(){
      queryCategory({
        id: 0
      }).then(res=>{
        let result = res.detail.children;
        result.forEach(item=>{
          if(item.haschildren === 1){
            item.hasChildren = true
          }
        })
        this.tableData = result;
      })
    },
    //添加类目
    addCategory({type, pid}, row){
      this.editType = type;
      this.categoryPid = pid;
      this.dialogVisible = true;
      //编辑时获取类目id
      if(this.editType === 'update'){
        this.categoryId = row.id;
        this.categoryInfo = row;
      }else{
        this.categoryId = null;
        this.$nextTick(()=>{
          this.$refs['category'].init();
        })
      }
    },
    /* handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    }, */
    //获取子类目
    load(tree, treeNode, resolve) {
        /* if(!this.treeIds.includes(tree.id)){
            this.treeIds.push(tree.id)
        } */
        queryCategory({
          id: tree.id
        }).then(res=>{
          if(res.result == 200){
            console.log('子类目', res.detail.children)
            let list = res.detail.children;
            list.forEach(item=>{
              if(item.haschildren === 1){
                item.hasChildren = true
              }
            })
            if(list.length === 0){
              resolve([])
            }else{
              resolve(list)
            }
          }else{
            resolve([])
            this.$message.warning(res.description)
          }
        })
    },
    //取消操作
    cancelOperate(){
      this.dialogVisible = false;
      this.categoryId = null;
      this.categoryPid = null;
    },
    //删除类目
    deleteRow(row){
      sureMessageBox(row.haschildren === 1?'该操作将删除该类目下的子类目，是否继续？':'是否确认删除该类目？').then(res=>{
        console.log(res)
        deleteCategory({
          id: row.id
        }).then(res=>{
          this.$message.success('删除成功');
          //this.$refs.tableDom.store.states.lazyTreeNodeMap
          //可以获取最新的当前节点数据 所以 当我们删除节点 或者修改节点后
          //再次调用接口传上一级节点的id 查询数据之后 把数据赋值给上一级节点即可
          this.getParentList(row.pid)
          
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    //更新当前节点的父节点的数据
    getParentList(pid){
      if(pid === 0){
        this.getList();
        return false;
      }
      queryCategory({
          id: pid
        }).then(res=>{
          let result = res.detail.children;
          result.forEach(item=>{
            if(item.haschildren === 1){
              item.hasChildren = true
            }
          })
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, pid, result);
          
        })
    },
    //重置加载状态
    /* refreshRow(){
      for(let i in this.treeIds){
          this.$refs.table.store.states.treeData[this.treeIds[i]].loaded = false;
          this.$refs.table.store.states.treeData[this.treeIds[i]].expanded = false;
      }
    } */
  }
}
</script>

<style lang="scss" scoped>
  .searchbox{
    margin: 0 10px 20px;
  }
  .pagebox{
    margin: 20px 0;
    text-align: right;
  }
</style>

<style lang="scss">
  .imgbox{
    width: 100%;
    height: 80px;
    display: flex;
    justify-items: center;
    align-items: center;
    img{
      width: 100%;
      height: auto;
    }
  }
</style>