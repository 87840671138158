<template>
  <div class="wrapper">
      <el-form ref="form"  :model="form" :rules="rules" label-width="180px" style="width:95%;">
        <el-form-item label="类目名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入类目名称"></el-input>
        </el-form-item>
        <el-form-item label="类目关键字" prop="keywords">
          <el-input v-model="form.keywords" placeholder="请输入类目关键字（以;号隔开）"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="form.description" placeholder="请输入描述"></el-input>
        </el-form-item>
        <el-form-item label="父类目ID" prop="pid" v-show="false">
          <el-input v-model="form.pid" readonly></el-input>
        </el-form-item>
        <el-form-item label="类目图标" prop="icon_url">
          <el-upload ref='upload' :limit="1" accept=".jpg,.jpeg,.png,.JPG,.JPEG" :class="{hide: form.icon_url}" :on-change="handleChange"
            action="#" list-type="picture-card" :file-list="fileList" :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove" :auto-upload="false">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="类目大图" prop="pic_url">
          <el-upload ref='upload1' :limit="1" accept=".jpg,.jpeg,.png,.JPG,.JPEG" :class="{hide: form.pic_url}" :on-change="handleChange1"
            action="#" list-type="picture-card" :file-list="fileList1" :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove1" :auto-upload="false">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="类目等级" prop="level">
          <el-input v-model="form.level"></el-input>
        </el-form-item> -->
        <el-form-item label="类目排序（越大越靠前）" prop="sort">
          <el-input v-model="form.sort" maxlength="3" placeholder="请输入类目排序" oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">取 消</el-button>
          <el-button type="primary" @click="submit('form')">确 定</el-button>
        </el-form-item>
      </el-form>

      <el-dialog :visible.sync="imgDialogVisible" title="图片预览" append-to-body>
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
  </div>
</template>

<script>
import { sureMessageBox } from '@/utils/common'
import { addOrModify, upLoadFileObj } from '@/api/itemCategory'
export default {
  name:"editcategory",
  components:{},
  props:{
    editType: {
      type: String,
      default: 'add'
    },
    categoryId: {
      type: Number,
      default: null
    },
    categoryPid: {
      type: Number,
      default: null
    },
    categoryInfo: {
      type: Object
    }
  },
  data(){
    return {
      rules: {
        name: [
          { required: true, message: '请输入商品类目名称', trigger: 'blur' }
        ],
        keywords: [
          { required: true, message: '请输入类目关键字', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ],
        icon_url: [
          { required: true, message: '请上传类目图标', trigger: 'change' }
        ],
        pic_url: [
          { required: true, message: '请上传类目大图', trigger: 'change' }
        ],
        sort: [
          { required: true, message: '请输入类目排序', trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      form: {
        name: '',
        keywords: '',
        description: '',
        pid: '',
        icon_url: '',
        pic_url: '',
        // level: '',
        sort: ''
      },
      dialogImageUrl: '',
      imgDialogVisible: false,
      fileList: [],
      fileList1: []
    }
  },
  watch:{
    categoryPid: {
      handler(newVal, oldVal) {
        if(newVal !== null){
          this.form.pid = newVal;
          console.log('pid：',this.categoryPid)
        }
      },
      immediate: true
    },
    categoryId: {
      handler(newVal, oldVal) {
        if(newVal !== null){
          console.log(this.categoryInfo)
          this.form =　{
            name: this.categoryInfo.name,
            keywords: this.categoryInfo.keywords,
            description:  this.categoryInfo.description,
            pid: this.form.pid,
            icon_url: this.categoryInfo.icon_url,
            pic_url: this.categoryInfo.pic_url,
            // level:  this.categoryInfo.level,
            sort: this.categoryInfo.sort
          }
          this.fileList = [{
            name: newVal,
            url: this.categoryInfo.icon_url
          }]
          this.fileList1 = [{
            name: newVal,
            url: this.categoryInfo.pic_url
          }]
          console.log('id', newVal)
        }
      },
      immediate: true
    }
  },
  created(){},
  mounted(){
    
  },
  computed:{},
  methods:{
    //上传图片
    handleChange(file, fileList) {
      let formData = new FormData();
					formData.append('userid', this.GLOBAL.adminId());
					formData.append('upfilelist', file.raw);
      upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId()
			}).then( res=> {
        console.log(res)
        this.form.icon_url = res.detail[0].path;
      })
    },
    handleChange1(file, fileList) {
      let formData = new FormData();
					formData.append('userid', this.GLOBAL.adminId());
					formData.append('upfilelist', file.raw);
      upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId()
			}).then( res=> {
        console.log(res)
        this.form.pic_url = res.detail[0].path;
      })
    },
    handleRemove(file, fileList) {
      console.log(fileList)
      this.form.icon_url = ""
    },
    handleRemove1(file, fileList) {
      console.log(fileList)
      this.form.pic_url = ""
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgDialogVisible = true;
    },
    //取消操作
    cancelFn(){
      this.$emit('cancelOperate')
    },
    //重置表单字段
    reset(){
      this.form = {
        name: '',
        keywords: '',
        description: '',
        pid: '',
        icon_url: '',
        pic_url: '',
        level: '',
        sort: ''
      }
      this.cancelFn();
    },
    init(){
      this.form = {
        name: '',
        keywords: '',
        description: '',
        pid: this.categoryPid,
        icon_url: '',
        pic_url: '',
        level: '',
        sort: ''
      }
      this.$refs.upload.clearFiles();
      this.$refs.upload1.clearFiles();
    },
    //提交表单
    submit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            "name": this.form.name, //类目名称
            "keywords": this.form.keywords, //类目关键字
            "description": this.form.description, //描述
            "pid": this.form.pid, //父类目ID
            "icon_url": this.form.icon_url, //类目体表URL地址
            "pic_url": this.form.pic_url, //类目大图URL地址
            "level": this.form.level, //类目登记
            "sort": this.form.sort //类目排序，越大越靠前
          }
          if(this.editType === 'update'){
            data.id = this.categoryId;
            sureMessageBox('是否确认提交修改？').then(res=>{
              addOrModify(data).then(res=>{
                console.log(res)
                this.$message.success('修改成功')
                this.$parent.$parent.getParentList(this.categoryPid);
                // this.$parent.$parent.refreshRow();
                this.reset();
              })
            }).catch(err=>{
              
            })
            
          }else{
            addOrModify(data).then(res=>{
              console.log(res)
              this.$message.success('添加成功')
              this.$parent.$parent.getParentList(this.categoryPid);
              // this.$parent.$parent.refreshRow();
              this.reset();
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
    }
    
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .hide{
      &>div.el-upload--picture-card {
        display: none;
      }
    }
    
  }
</style>